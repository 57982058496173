export const environment = {
  production: true,
  server: 'https://api.traxinsights.com/rest/',
  touchlessFeedbackAppBaseURL: 'https://touchless.traxinsights.app/#/',
  cleaningAlertRequestURL: 'https://ca.traxinsights.app/#/',
  applicationTypeName: 'TRAX',
  poweredBy: '',
  projectTitle: 'TRAX',
  faviconUrl: 'assets/fav_icon.ico',
};
